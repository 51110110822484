<template>
    <transition-group
        data-testid="modal-container"
        tag="div"
        mode="out-in"
        enter-from-class="fade-enter modal-enter"
        leave-from-class="fade-leave modal-leave"
        enter-to-class="fade-enter-to modal-enter-to"
        leave-to-class="fade-leave-to modal-leave-to"
        enter-active-class="fade-enter-active modal-enter-active"
        leave-active-class="fade-leave-active modal-leave-active"
    >
        <!-- this element has z-index 41 so that it overlays all 40-level elements-->
        <div
            v-for="modal in modalStack"
            :key="modal.id"
            class="fixed inset-0 scrollbars-none overflow-auto z-[41]"
        >
            <div
                class="fixed backdrop bg-black-700 opacity-40 inset-0"
                @click="modal.events.close(undefined)"
            />
            <div class="min-h-full w-full flex justify-center items-center">
                <component
                    :is="modal.is"
                    :ref="'modal'"
                    v-bind="modal.props"
                    class="relative shadow-lg"
                    v-on="modal.events"
                >
                    <template
                        v-for="(slotNode, slot) of modal.slots"
                        :key="slot"
                        #[slot]="slotProps"
                    >
                        <component
                            :is="slotNode"
                            v-bind="slotProps"
                        />
                    </template>
                </component>
            </div>
        </div>
    </transition-group>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue';
import type { ComponentPublicInstance, Component } from 'vue';
import { IdGenerator } from '@/helpers/IdGenerator';
import type { ModalEvents, ModalProps, ModalSlots } from '@/mixins/modalMixin';

interface ModalConfig<ReturnType> {
    id: number;
    is: Component;
    props: ModalProps;
    events: Required<ModalEvents<ReturnType>>;
    slots: ModalSlots;
}

/**
 * Manages our modals and is used in conjunction with our modal plugin.
 * It allows opening multiple modals on top of each other.
 * Modals need to emit a `close` event in order to close themselves.
 * The components that should appear can be completely unopinionated, so you could just open a button for example :D
 *
 * IMPORTANT: This component is already instantiated via the ModalPlugin.
 * Avoid using it stand-alone.
 *
 * @todo move to @/layouts/ModalLayout as it represents a layout
 */
export default defineComponent({
    name: 'ModalContainer',
    setup: () => ({
        // we make this value shallow reactive, to only react to array changes and not make each modal component deeply reactive
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        modalStack: shallowReactive([] as Array<ModalConfig<any>>),
    }),
    methods: {
        /**
         * Opens a new modal on top of the modal stack
         */
        openModal<ReturnType>(
            component: Component,
            props: ModalProps = {},
            events: ModalEvents<ReturnType> = {},
            slots: ModalSlots = {},
        ) {
            const id = IdGenerator.get(component);
            const config = {
                id,
                is: component,
                props,
                events: {
                    ...events,
                    close: (val?: ReturnType) => {
                        events?.close?.(val);
                        this.removeModal(id);
                    },
                },
                slots,
            };

            this.modalStack.push(config);
        },

        removeModal(id: number) {
            const i = this.modalStack.findIndex((m) => m.id === id);
            if (i < 0) return;

            this.modalStack.splice(i, 1);
        },
        /**
         * Closes all modals of the modal stack
         */
        closeAll() {
            (
                this.$refs.modal as Array<ComponentPublicInstance<unknown, { close: CallableFunction }>> | undefined
            )?.forEach((ref) => {
                ref.close();
            });
        },
    },
});
</script>
