import {
    getDocument,
    downloadDocument,
    getTotalDownloads,
    getDiscussion,
    toggleFollowDocument,
    updateDocument,
} from '@/api/backend/document';
import { rateDocument } from '@/api/gateway/legacy-api/document';
import { getDocumentSummary } from '@/api/gateway/alchemist';
import {
    deletePost,
    editPost,
    reportPost,
    togglePinnedPost,
    createComment,
    deleteComment,
    editComment,
    reportComment,
    toggleBestComment,
    getComments,
} from '@/api/backend/post';
import { trackStickyElement } from '@/modules/sticky';
import { findPostIndexById } from '../../utils/feedUtils';

const types = {
    PROPAGATE_IDENTITY_CHANGE: 'PROPAGATE_IDENTITY_CHANGE',
    SET_DISCUSSION_PAGE: 'SET_DISCUSSION_PAGE',
    SET_DISCUSSION_PROGRESS: 'SET_DISCUSSION_PROGRESS',
    SET_DISCUSSION_COMPLETE: 'SET_DISCUSSION_COMPLETE',
    APPEND_DISCUSSION_POSTS: 'APPEND_DISCUSSION_POSTS',
    CLEAR_DISCUSSION: 'CLEAR_DISCUSSION',
    SET_DOCUMENT: 'SET_DOCUMENT',
    SET_DOCUMENT_DOWNLOAD_COUNT: 'SET_DOCUMENT_DOWNLOAD_COUNT',
    SET_DOCUMENT_ROTATION: 'SET_DOCUMENT_ROTATION',
    SET_SHOW_DISCUSSION: 'SET_SHOW_DISCUSSION',
    PDF_VIEWER_INITIALIZED: 'PDF_VIEWER_INITIALIZED',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
    SET_DOCUMENT_USER_UPVOTED: 'SET_DOCUMENT_USER_UPVOTED',
    SET_DOCUMENT_USER_FOLLOWED: 'SET_DOCUMENT_USER_FOLLOWED',
    SET_REPORTED_POST: 'SET_REPORTED_POST',
    SET_REPORTED_COMMENT: 'SET_REPORTED_COMMENT',
    ADD_OR_UPDATE_POST_IN_DISCUSSION: 'ADD_OR_UPDATE_POST_IN_DISCUSSION',
    ADD_OR_UPDATE_COMMENT_IN_POST: 'ADD_OR_UPDATE_COMMENT_IN_POST',
    UPDATE_POST_COMMENTS: 'UPDATE_POST_COMMENTS',
    REMOVE_POST_FROM_FEED: 'REMOVE_POST_FROM_FEED',
    REMOVE_COMMENT_FROM_POST: 'REMOVE_COMMENT_FROM_POST',
    TOGGLE_BEST_COMMENT: 'TOGGLE_BEST_COMMENT',
    SET_DOCUMENT_ADDED_STUDYLIST: 'SET_DOCUMENT_ADDED_STUDYLIST',
    UPDATE_DOCUMENT_AVG_STAR_SCORE: 'UPDATE_DOCUMENT_AVG_STAR_SCORE',
    UPDATE_DOCUMENT_USER_STAR_VOTE: 'UPDATE_DOCUMENT_USER_STAR_VOTE',
    UPDATE_DOCUMENT_RATINGS_COUNT: 'UPDATE_DOCUMENT_RATINGS_COUNT',
    SET_SUMMARY: 'SET_SUMMARY',
    SET_TOOLBAR_HEIGHT: 'SET_TOOLBAR_HEIGHT',
};

const state = {
    document: sdWindow?.document || null,

    discussionFeed: [],

    // the first page is already fetched on the backend
    // and passed through the `sdWindow` global variable
    discussionPage: 1,
    discussionLoading: false,
    discussionComplete: false,

    showDiscussion: false,
    pdfViewerInitialized: false,

    toolbarHeight: 0,
};

function appendDiscussionPosts(state, posts) {
    posts.forEach((post) => {
        const postId = post.postDetails.id;
        let index = state.discussionFeed.length;

        if (state.discussionFeed.find((item) => item.postDetails.id === postId)) {
            // when post is already in feed, replace it
            index = findPostIndexById(state.discussionFeed, postId);
            if (index === -1) {
                return;
            }
        }

        state.discussionFeed[index] = post;
    });
}

const isDocumentFeed = sdWindow?.feed?.type === 'document';

if (isDocumentFeed) {
    appendDiscussionPosts(state, sdWindow?.feed?.items || []);
}

const mutations = {
    [types.PROPAGATE_IDENTITY_CHANGE](state, { postId, user, isAnonymous }) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);
        if (post.isOwner) {
            if (isAnonymous) {
                post.postDetails.anonymousUser = user;
                post.postDetails.user = null;
            } else {
                post.postDetails.user = user;
                post.postDetails.anonymousUser = null;
            }
            post.postDetails.isAnonymous = isAnonymous;
        }

        /* eslint-disable no-param-reassign */
        post.comments.forEach((comment) => {
            /* eslint-disable no-param-reassign */
            if (comment.isOwner) {
                if (isAnonymous) {
                    comment.anonymousUser = user;
                    comment.user = null;
                } else {
                    comment.user = user;
                    comment.anonymousUser = null;
                }
                comment.isAnonymous = isAnonymous;
            }
            /* eslint-enable no-param-reassign */
        });
        /* eslint-enable */
    },
    [types.SET_DOCUMENT](state, payload) {
        state.document = {
            ...payload,
            summary: state.document?.summary,
            summary_code: state.document?.summary_code,
            has_ai_content: state.document?.has_ai_content,
        };
    },
    [types.SET_DOCUMENT_DOWNLOAD_COUNT](state, payload) {
        state.document.downloads = payload;
    },
    [types.SET_DISCUSSION_PAGE](state, payload) {
        state.discussionPage = payload;
    },
    [types.SET_DISCUSSION_PROGRESS](state, payload) {
        state.discussionLoading = payload;
    },
    [types.SET_DISCUSSION_COMPLETE](state, payload) {
        state.discussionComplete = payload;
    },
    [types.CLEAR_DISCUSSION](state) {
        state.discussionFeed = [];
    },
    [types.APPEND_DISCUSSION_POSTS](state, posts) {
        appendDiscussionPosts(state, posts);
    },
    [types.SET_SHOW_DISCUSSION](state, val) {
        state.showDiscussion = val;
    },
    [types.PDF_VIEWER_INITIALIZED](state) {
        state.pdfViewerInitialized = true;
    },
    [types.UPDATE_DOCUMENT](state, payload) {
        state.document = {
            ...payload,
            summary: state.document?.summary,
            summary_code: state.document?.summary_code,
            has_ai_content: state.document?.has_ai_content,
        };
    },
    [types.SET_DOCUMENT_USER_UPVOTED](state, userUpvoted) {
        state.document.user_upvoted = userUpvoted;
    },
    [types.SET_DOCUMENT_USER_FOLLOWED](state, followed) {
        state.document.user_followed = followed;
    },
    [types.SET_REPORTED_POST](state, postId) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);
        post.postDetails.isReported = true;
        post.postDetails.userReport = true;
    },
    [types.SET_REPORTED_COMMENT](state, { postId, commentId }) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);
        const comment = post.comments.find((item) => {
            return item.id === commentId;
        });
        comment.isReported = true;
        comment.userReport = true;
    },
    [types.ADD_OR_UPDATE_POST_IN_DISCUSSION](state, post) {
        const postId = post.postDetails.id;
        const postIndex = findPostIndexById(state.discussionFeed, postId);
        const storePost = state.discussionFeed[postIndex];

        /* eslint-disable no-param-reassign */
        // if the post exists we just replace it with its updated version
        if (postIndex !== -1) {
            /* eslint-disable no-param-reassign */
            post.comments = storePost.comments;
            post.totalComments = storePost.totalComments;
            /* eslint-enable no-param-reassign */
            state.discussionFeed[postIndex] = post;
            return;
        }
        /* eslint-enable */

        // if it's a new post we add it to the beginning of the feed
        state.discussionFeed.unshift(post);
    },
    [types.ADD_OR_UPDATE_COMMENT_IN_POST](state, comment) {
        const postIndex = findPostIndexById(state.discussionFeed, comment.context.id);
        const post = state.discussionFeed[postIndex];

        const commentIndex = post.comments.findIndex((postComment) => {
            return postComment.id === comment.id;
        });

        // if the comment exists we just replace it with its updated version
        if (commentIndex !== -1) {
            post.comments[commentIndex] = comment;
            return;
        }

        // if it's a new comment we push it to the post
        post.comments.push(comment);
        post.totalComments += 1;
    },
    [types.UPDATE_POST_COMMENTS](state, { postId, comments = [] }) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);
        if (!post) return;

        post.comments = comments;
    },
    [types.REMOVE_POST_FROM_FEED](state, postId) {
        const postIndex = findPostIndexById(state.discussionFeed, postId);

        if (postIndex === -1) {
            throw new Error('Post not found');
        }

        state.discussionFeed.splice(postIndex, 1);
    },
    [types.REMOVE_COMMENT_FROM_POST](state, { commentId, postId }) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);
        const commentIndex = post.comments.findIndex((comment) => {
            return comment.id === commentId;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found');
        }

        post.comments.splice(commentIndex, 1);
    },
    [types.UPDATE_DOCUMENT_AVG_STAR_SCORE](state, averageRate) {
        state.document.avg_star_score = averageRate;
    },
    [types.UPDATE_DOCUMENT_USER_STAR_VOTE](state, userStarVote) {
        state.document.user_star_vote = userStarVote;
    },
    [types.UPDATE_DOCUMENT_RATINGS_COUNT](state, ratingsCount) {
        state.document.ratings_count = ratingsCount;
    },
    [types.TOGGLE_BEST_COMMENT](state, { commentId, postId }) {
        const post = state.discussionFeed.find((item) => item.postDetails.id === postId);

        if (post === undefined) {
            throw new Error('Post not found, cannot toggle best comment.');
        }

        const bestComment = post.comments.find((comment) => comment.id === commentId);

        if (bestComment === undefined) {
            throw new Error('Comment not found, cannot toggle best comment.');
        }

        post.postDetails.hasBestAnswer = !post.postDetails.hasBestAnswer;
        bestComment.isBest = !bestComment.isBest;
    },
    [types.SET_DOCUMENT_ADDED_STUDYLIST](state, studyListId) {
        state.document.study_list_id = studyListId;
    },
    [types.SET_SUMMARY](state, response) {
        state.document.summary_code = response?.status;
        if (response.status === 200 && response.data?.summary) {
            state.document.summary = response.data.summary;
            state.document.has_ai_content = true;
        }
    },
    [types.SET_TOOLBAR_HEIGHT](state, height) {
        state.toolbarHeight = height;
    },
};

const actions = {
    downloadDocument: ({ dispatch, getters }, { id, fileName, recaptchaToken, getPdf }) => {
        return downloadDocument(id, fileName, recaptchaToken, getPdf).then(() => {
            if (getters.currentDocument.id === id) {
                dispatch('updateDownloads');
            }
        });
    },
    propagateIdentityChange({ commit }, { postId, user, isAnonymous }) {
        commit(types.PROPAGATE_IDENTITY_CHANGE, {
            postId,
            user,
            isAnonymous,
        });
    },
    toggleFollowDocument: ({ commit, getters }, id) => {
        return toggleFollowDocument(id).then(({ data: isFollowed }) => {
            if (getters.currentDocument.id === id) {
                commit(types.SET_DOCUMENT_USER_FOLLOWED, isFollowed);
                return isFollowed;
            }
            return undefined;
        });
    },
    toggleStudylistItem: ({ commit, dispatch }, document) => {
        if (document.study_list_id) {
            return dispatch(
                'studylists/deleteStudylistItem',
                { type: 'document', id: document.id },
                { root: true },
            ).then(() => {
                commit(types.SET_DOCUMENT_ADDED_STUDYLIST, null);
            });
        }
        return dispatch('studylists/addStudylistItem', { type: 'document', id: document.id }, { root: true }).then(
            (response) => {
                commit(types.SET_DOCUMENT_ADDED_STUDYLIST, response.data.study_list_id);
            },
        );
    },
    reloadDocument({ dispatch, state }) {
        return dispatch('retrieveDocument', {
            id: state.document.id,
            slug: state.document.slug,
        });
    },
    retrieveDocument({ commit }, { id, slug }) {
        return getDocument(id, slug).then(({ data }) => {
            commit(types.SET_DOCUMENT, data.data);
            return data.data;
        });
    },
    resetDiscussion: ({ commit }) => {
        commit(types.CLEAR_DISCUSSION);
        commit(types.SET_DISCUSSION_PAGE, 0);
        commit(types.SET_DISCUSSION_COMPLETE, false);
    },
    isPostLoaded: (ctx, postId) => {
        return state.discussionFeed.find((item) => item.postDetails.id === parseInt(postId, 10));
    },
    loadPost: ({ commit, state }, postId) => {
        commit(types.SET_DISCUSSION_PROGRESS, true);
        return getDiscussion(state.document.id, 1, postId)
            .then((response) => commit(types.APPEND_DISCUSSION_POSTS, response.data.data))
            .finally(() => commit(types.SET_DISCUSSION_PROGRESS, false));
    },
    loadDiscussionPage: ({ commit, state }, { id = null, page = 1 }) => {
        commit(types.SET_DISCUSSION_PROGRESS, true);

        return getDiscussion(id ?? state.document.id, page)
            .then((response) => {
                if (!response.data.links.next) {
                    commit(types.SET_DISCUSSION_COMPLETE, true);
                }
                commit(types.SET_DISCUSSION_PAGE, response.data.meta.current_page);
                commit(types.APPEND_DISCUSSION_POSTS, response.data.data);

                return response.data;
            })
            .finally(() => {
                commit(types.SET_DISCUSSION_PROGRESS, false);
            });
    },
    loadNextDiscussionPage: ({ dispatch, state }) => {
        if (!state.discussionComplete) {
            dispatch('loadDiscussionPage', {
                page: state.discussionPage + 1,
            });
        }
    },
    trackStickyElement,
    updateDocument({ commit }, { id, payload }) {
        return updateDocument(id, payload).then((response) => {
            commit('UPDATE_DOCUMENT', response.data.document);
            return true;
        });
    },
    updateDownloads: ({ commit, getters }) => {
        return getTotalDownloads(getters.currentDocument.id)
            .then((response) => {
                commit(types.SET_DOCUMENT_DOWNLOAD_COUNT, response.data.downloads);
            })
            .catch(() => {
                // do nothing - its not that important
            });
    },
    toggleDiscussion: ({ commit, getters, rootGetters }) => {
        commit(types.SET_SHOW_DISCUSSION, rootGetters['ui/screen'].maxSM ? !getters.isShowingDiscussion : null);
    },
    rotateDocument: ({ commit }, degrees) => {
        commit(types.SET_DOCUMENT_ROTATION, degrees);
    },
    pdfViewerInitialized: ({ commit }) => {
        commit(types.PDF_VIEWER_INITIALIZED);
    },
    addPostToDiscussion({ commit }, post) {
        commit(types.ADD_OR_UPDATE_POST_IN_DISCUSSION, post);
    },
    async deletePost({ commit }, { context, postId }) {
        await deletePost(context, postId);
        commit(types.REMOVE_POST_FROM_FEED, postId);
    },
    async editPost({ commit }, { context, post }) {
        const response = await editPost(context, post);
        commit(types.ADD_OR_UPDATE_POST_IN_DISCUSSION, response.data);
        return { post: response.data };
    },
    async reportPost({ commit }, { context, postId, reason, additional }) {
        await reportPost(context, postId, reason, additional);
        commit(types.SET_REPORTED_POST, postId);
    },
    getSummary({ commit }) {
        getDocumentSummary(state.document.id)
            .then((response) => {
                commit(types.SET_SUMMARY, response);
            })
            .catch((error) => {
                commit(types.SET_SUMMARY, error.response);
            });
    },
    // eslint-disable-next-line no-unused-vars
    async togglePinnedPost({ commit }, { context, postId, targetId }) {
        await togglePinnedPost(context, postId, targetId);
    },
    async createComment({ commit }, { context, comment }) {
        const response = await createComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async deleteComment({ commit }, { context, postId, commentId }) {
        await deleteComment(context, commentId);
        commit(types.REMOVE_COMMENT_FROM_POST, { commentId, postId });
    },
    async editComment({ commit }, { context, comment }) {
        const response = await editComment(context, comment);
        commit(types.ADD_OR_UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    async reportComment({ commit }, { context, postId, commentId, reason, additional }) {
        await reportComment(context, commentId, reason, additional);
        commit(types.SET_REPORTED_COMMENT, { postId, commentId });
    },
    async toggleBestComment({ commit }, { context, postId, commentId }) {
        await toggleBestComment(context, commentId);
        commit(types.TOGGLE_BEST_COMMENT, { commentId, postId });
    },
    async loadPostComments({ commit }, { postId }) {
        const response = await getComments('document', postId);

        commit(types.UPDATE_POST_COMMENTS, {
            postId,
            comments: response.data.comments,
        });
    },
};

const getters = {
    currentDocument: (state) => state.document,
    currentDocumentUniversity: (state) =>
        state.document && state.document.course && state.document.course.university
            ? state.document.course.university
            : null,
    isShowingDiscussion: (state) => state.showDiscussion,
    pdfViewerInitialized: (state) => state.pdfViewerInitialized,
    currentDocumentDiscussionLoading: (state) => state.discussionLoading,
    currentDocumentDiscussionComplete: (state) => state.discussionComplete,
    currentDocumentDiscussionPosts: (state) => state.discussionFeed,
    toolbarHeight: (state) => state.toolbarHeight,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
