import type { Plugin } from 'vue';
import type { ErrorEvent } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import eventProcessors from './eventProcessors';

export default {
    install(app) {
        if (import.meta.env.MIX_ENABLE_SENTRY === 'true') {
            const processors = Object.values(eventProcessors);

            Sentry.init({
                app,
                dsn: import.meta.env.MIX_SENTRY_DSN,
                environment: import.meta.env.MIX_SENTRY_ENVIRONMENT,
                logErrors: true,
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    }),
                    new Sentry.Replay(),
                ],
                tracesSampleRate: parseFloat(import.meta.env.MIX_SENTRY_TRACES_SAMPLE_RATE || '1.0'),
                sampleRate: parseFloat(import.meta.env.MIX_SENTRY_SAMPLE_RATE || '1.0'),
                tracePropagationTargets: ['localhost', import.meta.env.APP_URL as string],
                tracingOptions: {
                    trackComponents: true,
                },
                replaysSessionSampleRate: parseFloat(import.meta.env.MIX_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0'),
                replaysOnErrorSampleRate: parseFloat(import.meta.env.MIX_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),
                denyUrls: [
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    // Safari extensions
                    /safari-(web-)?extension:/,
                    // UserCentrics packages
                    /https?:\/\/(.+\.)?usercentrics\.eu/,
                ],
                ignoreErrors: [
                    // ignore error related with Google Sign-in in incognito pages because Google Sign-in is currently not supported in incognito mode on Chrome on iOS
                    /^Abgebrochen$/,

                    // ignore errors originating from the AS adLib
                    /^Cannot read properties of (undefined|null) \((reading|setting) .*\)/,
                    /^undefined is not an object \(evaluating .*\)/,
                    /^apntag.clearRequest is not a function/,
                    /^apntag\.clearRequest is not a function\. \(In 'apntag\.clearRequest\(\)', 'apntag\.clearRequest' is undefined\)/,
                    /^n.adSlot is null/,
                    /^document\.getElementsByTagName\(...\)\[0] is undefined/,
                    /^[A-Za-z] is undefined$/,
                    /^illegal character U\+FFFD/,
                    /^Can't find variable: BigInt$/,
                    /^null is not an object \(evaluating 'i\.contentWindow\.document'\)/,
                    /^Cannot set property ['"]advertiserId['"] of undefined/,
                    /^Cannot read property ['"]adSlot['"] of undefined/,

                    // ignore error related to bad connection/timeout
                    /^timeout of 0ms exceeded$/,
                ],
                /* this will invoke every event processor defined in ./eventProcessors/index.js */
                beforeSend(event, hint) {
                    let processedEvent: ErrorEvent | null = event;
                    for (const processor of processors) {
                        processedEvent = processor(processedEvent, hint);

                        if (!processedEvent) return null;
                    }

                    return processedEvent;
                },
            });

            // eslint-disable-next-line no-param-reassign
            app.config.warnHandler = (msg) => {
                Sentry.captureMessage(msg, 'warning');
            };
        }
    },
} as Plugin;
